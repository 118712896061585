.splash__bloc {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f2f3;
}
.splash-picture__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 200px;
}
.splash-picture__image {
  max-width: 100%;
  height: auto;
}
@media only screen and (max-width: 700px) {
  .splash-picture__container {
    max-width: 100px;
  }
}